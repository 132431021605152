<template>
  <div v-if="!searchLoading" class="search">
    <div class="container">
      <div class="all-shops__nav search__crumbs">
        <locale-router-link to="" class="crumbs-link main-page-link">
          {{ $t("home") }}
        </locale-router-link>
        <a class="crumbs-link all-shops-link"> {{ $t("searchingResults") }}</a>
      </div>
      <div class="header-top__search">
        <form @submit.prevent.enter="searchStart">
          <input
            v-model="termSearch"
            type="text"
            :placeholder="$t(searchTitle)"
          />
          <div
            class="page-search-filter"
            :class="{ 'is-active': isShowList }"
            @click="showList"
          ></div>
        </form>

        <div
          class="header-top__search-list"
          :class="{ 'is-active': isShowList }"
        >
          <a
            v-for="(item, index) in searchFilters"
            :key="index"
            class="search-link"
            :class="{ 'is-active': item.label === searchTitle }"
            @click="replacement(item)"
            >{{ $t(item.label) }}</a
          >
        </div>
      </div>
    </div>
    <div v-if="resultFound" class="container">
      <div class="search__found">
        <div class="search__found-title">{{ $t("found") }}</div>
        <div class="search__found-amount">{{ resultFound }}</div>
      </div>
      <div
        v-if="entity === 'products' && results"
        class="search__found-products"
      >
        <product-card v-for="item in results" :key="item.id" :item="item" />
      </div>
      <div v-if="entity === 'promos' && results" class="search__stocks">
        <div class="page-stocks__items" v-if="results">
          <locale-router-link
            v-for="item in results"
            :key="item.id"
            :to="getComponent(item)"
            class="stocks__item"
            ><img
              v-lazy="getStockImage(item)"
              alt="stock image"
              width="380"
              height="320"
          /></locale-router-link>
        </div>
      </div>
      <news-list v-if="entity === 'news'" :items="results" />

      <div v-if="entity === 'recipes'" class="prescription">
        <recipe-list :items="results" />
      </div>
    </div>

    <div v-if="!resultFound && !searchLoading" class="search-result">
      <div class="container">
        <div class="search-result__content">
          <div class="search-result__header">
            <p class="search-result__find">
              Найдено
              {{ resultFound }}
            </p>
            <h1 class="search-result__title title">
              {{ $t("nothingQuery") }}
            </h1>
            <div class="search-result__descr">
              <!-- {{ $t("adjustRequest") }} -->
              Попробуйте скорректируйте ваш запрос или посмотрите в других
              разделах сайта
            </div>
          </div>
        </div>
        <div class="search__result-note">
          <h2 class="title">{{ $t("payAttention") }}</h2>
          <div class="search__result-products">
            <product-card
              v-for="item in products"
              :key="item.id"
              :item="item"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="loader-mini__container">
    <div class="loader-mini"></div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { actionTypes } from "@/store/modules/search";
import { getSearchResults } from "@/api/search";
import { getAllProducts } from "@/api/products";
import { gtmDataLayer } from "../helpers";

export default {
  name: "SearchResult",
  data() {
    return {
      searchTitle: null,
      searchEntity: null,
      isShowList: false,
      termSearch: null,
      found: 0,
      results: null,
      products: null,
      searchLoading: false,
      searchPagination: { page: 1, pageSize: 20, isLastPage: false },
      searchFilters: [
        {
          label: "byGoods",
          entity: "products",
        },
        {
          label: "byShares",
          entity: "promos",
        },
        {
          label: "onTheNews",
          entity: "news",
        },
        {
          label: "prescription",
          entity: "recipes",
        },
      ],
    };
  },
  components: {
    ProductCard: () => import("@/components/products/ProductCard.vue"),
    NewsList: () => import("@/components/news/NewsList.vue"),
    RecipeList: () => import("@/components/recipe/RecipeList.vue"),
  },
  computed: {
    ...mapState("searchModule", ["term", "entity", "isSearchStart", "label"]),
    resultFound() {
      return this.results?.length || 0;
    },
  },
  watch: {
    term: {
      handler() {
        this.results = null;
        this.searchLoading = true;
        if (this.isSearchStart) {
          getSearchResults({
            term: this.term,
            entity: this.entity,
            pagination: this.searchPagination,
          })
            .then((results) => {
              this.results = results.data;
              this.found = results.meta.pagination.total;
              this.searchLoading = false;
              gtmDataLayer({
                event: "search_run",
                type: "search",
                typeSearch: this.label,
                textSearch: this.term,
              });
              this.changeIsSearchStart(false);
              if (
                this.searchPagination.page === results.meta.pagination.pageCount
              ) {
                this.searchPagination.isLastPage = true;
              } else {
                this.searchPagination.isLastPage = false;
              }
            })
            .catch(() => {
              this.searchLoading = false;
            });
        }
      },
    },
    entity: {
      handler() {
        this.results = null;
        this.products = null;
        this.searchLoading = true;
        if (this.isSearchStart) {
          getSearchResults({
            term: this.term,
            entity: this.entity,
            pagination: this.searchPagination,
          }).then((results) => {
            this.results = results.data;
            this.searchLoading = false;
            this.found = results.meta.pagination.total;
            this.changeIsSearchStart(false);
            if (
              this.searchPagination.page === results.meta.pagination.pageCount
            ) {
              this.searchPagination.isLastPage = true;
            } else {
              this.searchPagination.isLastPage = false;
            }
          });
        }
      },
    },
    results: {
      handler() {
        if (!this.results?.length) {
          getAllProducts(1, 5).then((response) => {
            this.products = response.data;
          });
        }
      },
    },
  },
  methods: {
    ...mapActions("searchModule", {
      searchTerm: actionTypes.getSearchTerm,
      changeIsSearchStart: actionTypes.getIsSearchStart,
      entitySearch: actionTypes.getSearchEntity,
      labelSearch: actionTypes.getSearchLabel,
    }),
    getStockImage(item) {
      return this.$helpers.getAbsolutePath(
        item.attributes?.preview_desktop?.data?.attributes?.url
      );
    },
    addPaginationSize() {
      this.searchPagination.page++;
      this.searchLoading = true;

      getSearchResults({
        term: this.term,
        entity: this.entity,
        pagination: this.searchPagination,
      }).then((results) => {
        this.results = [...this.results, ...results.data];
        this.searchLoading = false;
        this.found = results.meta.pagination.total;
        this.changeIsSearchStart(false);
        if (this.searchPagination.page === results.meta.pagination.pageCount) {
          this.searchPagination.isLastPage = true;
        }
      });
    },

    replacement(item) {
      this.searchTitle = item.label;
      this.isShowList = false;
      this.searchEntity = item.entity;
    },
    searchStart() {
      if (this.$router.app._route.fullPath === "/search") {
        this.searchTerm(this.termSearch);
        this.entitySearch(this.searchEntity);
        this.labelSearch(this.$t(this.searchTitle));
        this.changeIsSearchStart(true);
      } else {
        this.$router.push("/search");
        this.searchTerm(this.termSearch);
        this.entitySearch(this.searchEntity);
        this.labelSearch(this.$t(this.searchTitle));
        this.changeIsSearchStart(true);
      }
    },
    showList() {
      this.isShowList = !this.isShowList;
    },
    getComponent(item) {
      if (item.attributes.promo_type === "only_discount") {
        return "stocks/products/" + item.id;
      } else {
        return "stocks/" + item.id;
      }
    },
  },

  mounted() {
    this.searchTitle = this.searchFilters[0].label;
    this.searchEntity = this.searchFilters[0].entity;
    this.searchLoading = true;

    getSearchResults({
      term: this.term,
      entity: this.entity,
      pagination: this.searchPagination,
    }).then((results) => {
      this.results = results.data;
      console.log("GTM SEARCH");
      gtmDataLayer({
        event: "search_run",
        type: "search",
        typeSearch: this.label,
        textSearch: this.term,
      });
      this.found = results.meta.pagination.total;
      this.searchLoading = false;
      if (this.searchPagination.page === results.meta.pagination.pageCount) {
        this.searchPagination.isLastPage = true;
      } else {
        this.searchPagination.isLastPage = false;
      }
    });
  },
};
</script>

<style lang="sass" scoped>
.crumbs-link
  &+&
    margin-left: 5px
    &::before
      content: '/'
      margin-right: 5px
  .loader-mini__container
    min-height: 50vh
</style>
