import axios from "@/plugins/axios";
import qs from "qs";

export const getSearchResults = async (
  { term, entity, pagination },
  cityId
) => {
  let queryPopulate = {};
  let field = "name";
  if (!cityId) {
    cityId =
      localStorage.key("selectedCity") &&
      localStorage.getItem("selectedCity") !== "undefined"
        ? JSON.parse(localStorage.getItem("selectedCity"))?.id
        : 1;
  }

  if (entity === "promos") {
    field = "label";
    queryPopulate = {
      populate: {
        preview_desktop: "*",
        preview_mob: "*",
        backgound_desktop: "*",
        backgound_mob: "*",
        discount: {
          populate: {
            products: "*",
          },
        },
        localizations: "*",
      },
      filters: {
        $and: [
          {
            [field]: {
              $containsi: term,
            },
          },
          {
            cities: {
              id: { $eq: cityId },
            },
          },
        ],
      },
    };
  }
  if (entity === "news") {
    field = "Header";
    queryPopulate = {
      populate: {
        news_category: "*",
        shops: "*",
        image: "*",
        localizations: "*",
      },
      filters: {
        $and: [
          {
            [field]: {
              $containsi: term,
            },
          },
          {
            cities: {
              id: { $eq: cityId },
            },
          },
        ],
      },
    };
  }
  if (entity === "products") {
    field = "name";
    queryPopulate = {
      populate: {
        image: "*",
        localizations: "*",
      },
      filters: {
        $and: [
          {
            [field]: {
              $containsi: term,
            },
          },
          {
            shops: {
              city: { id: { $eq: cityId } },
            },
          },
        ],
      },
    };
  }
  if (entity === "recipes") {
    field = "title";
    queryPopulate = {
      populate: {
        image: "*",
        author: {
          populate: "*",
        },
        recipe_details: "*",
        recipe_category: "*",
      },
      filters: {
        [field]: {
          $containsi: term,
        },
      },
    };
  }

  const query = qs.stringify(
    {
      pagination: {
        page: pagination?.page || 1,
        pageSize: pagination?.pageSize || 1500,
      },
      ...queryPopulate,
    },
    {
      encodeValuesOnly: true,
    }
  );

  const response = await axios.get(`/${entity}?${query}`);
  return response?.data;
};
